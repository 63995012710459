import React from 'react';
import Footer from '../Components/Footer';
import Navigation from '../Components/Navigation';
import contactImage from '../Assets/Icons/contact-us.png';
const ContactUs = () => {
  return (
    <>
      <Navigation isScrolled={true} />
      <div className="mt-36 mb-4 bg-white">
        <div className="row padding">
          <div className="col-lg-6">
            <div className="mx-30 mt-30">
              <div className="card border-2 border-aerogenGrey p-5">
                <h1 className="text-lg text-aerogenBlue">Contact Us</h1>
                <p className="text-sm mt-1 text-aerogenDarkGrey">
                  Feel free to contact us
                </p>
                <div className="row padding">
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label className="block text-aerogenBlue text-sm  mb-2">
                        Full Names
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label className="block text-aerogenBlue text-sm  mb-2">
                        Phone Number
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block text-aerogenBlue text-sm  mb-2">
                    Email
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-aerogenBlue text-sm  mb-2">
                    Message
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-5 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                  />
                </div>
                <div className="flex justify-end items-end">
                  <button className="bg-aerogenYellow text-black text-sm font-bold px-3 py-1 rounded-xl">Send</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={contactImage} alt="contact" className="h-fit w-full" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
