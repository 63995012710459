import React, { useState, useEffect } from 'react';
import Navigation from '../Components/Navigation';
import charge from '../Assets/Icons/charge.png';
import turbine from '../Assets/videos/wind_turbine_on_a_field_at_sunrise (1080p).mp4';
import tree from '../Assets/Icons/wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass 1.png';
import vision from '../Assets/Icons/7941763_3395012-removebg-preview 1.png';
import windTurbines from '../Assets/Icons/10120850_1351 1.png';
import CarImage from '../Assets/Icons/carImage.png'

import Footer from '../Components/Footer';
const Home = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Navigation isScrolled={isScrolled} />

<div className={`${isScrolled?'mt-36':'mt-0'}  bg-gradient-to-b from-aerogenGreen  to-aerogenBlue`}>
  <div className="flex">
    <div className="w-1/2 h-screen flex flex-col justify-center  p-4">
     <div className="flex space-x-4">
                <h1 className="text-5xl  text-white font-semibold">
                  Green energy has never 
                  <br />
                    been easier to <br />
                  generate
                </h1>
                <img src={charge} alt="charge" className="h-fit w-fit" />
              </div>
              <h1 className="mt-8 text-white text-lg">
                Renewable energy has economic advantages that extend <br/>
                 beyond steady, predictable electric rates - and Maine is in a <br/>
                  good position to capitalize on those opportunities.
              </h1>
              <h1 className="mt-8 text-white text-lg">
                Leading the energy revolution
              </h1>
              <button className="mt-8 rounded-full w-1/4 bg-aerogenBlue text-white px-4 py-1">
                Read more
              </button>
    </div>
     <div className="w-1/2 ">
      <div className="relative">
            <img src={CarImage} alt="image" className="h-fit w-full" />

      <div className="absolute top-0 left-0 w-full h-full p-4 flex items-end justify-center">
        <p className=" text-aerogenYellow text-lg  font-bold  mb-72 p-4">
          At the current pace, only 92% of the world’s population would have access to electricity in 2030, leaving 670 million people
unserved. - United Nations
        </p>
      </div>
    </div>
    </div>
  </div>
</div>
      {/* <div className="bg-gradient-to-b mt-36 from-aerogenGreen to-aerogenBlue p-0">
        <div className="mt-11 container mx-4 p-4">
          <div className="row padding">
            <div className="col-lg-8">
              <div className="flex">
                <h1 className="text-5xl  text-white font-semibold">
                  Green energy has
                  <br />
                  never been easier to <br />
                  generate
                </h1>
                <img src={charge} alt="charge" className="h-fit w-fit" />
              </div>
              <h1 className="mt-8 text-white text-lg">
                Renewable energy has economic advantages that extend beyond
                steady, predictable
                <br />
                electric rates - and Maine is in a good position to capitalize
                on those opportunities.
              </h1>
              <h1 className="mt-8 text-white text-lg">
                Leading the energy revolution
              </h1>
              <button className="mt-8 rounded-3xl bg-aerogenBlue text-white px-4 py-1">
                Read more
              </button>
              <h1 className="mt-8 text-aerogenYellow text-lg">
                At the current pace, only 92% of the world’s population would
                have
                <br />
                access to electricity in 2030, leaving 670 million people
                <br />
                unserved. - United Nations
              </h1>
            </div>
            <div className="col-lg-4">
              <div className="flex justify-center  h-screen">
                <div className="mt-3 ">
                  <video
                    controls
                    className="max-w-full max-h-full p-2"
                    src={turbine}
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-lg-6">
          <img src={tree} alt="tree" className=" h-fit w-full" />
        </div>
        <div className="col-lg-6">
          <div className="flex items-center justify-center">
            <div className="p-3 mt-11">
              <h1 className="text-4xl text-aerogenBlue mt-5">
                Future developments
              </h1>
              <h1 className="text-lg text-black mt-5">
                Let’s build green energy right, now. We want to help the the
                world reach its net zero goals – including unlocking the
                potential of floating offshore wind and its economic benefits in
                places
              </h1>
              <h1 className="text-lg text-black mt-5">
                Green energy, characterized by renewable sources like solar,
                wind, and hydroelectric power, not only mitigates environmental
                impact but also drives sustainable economic growth, offering a
                promising pathway towards a cleaner and more resilient energy
                future
              </h1>
              <h1 className="text-lg text-black mt-5">
                Rising commodity, energy and shipping prices have increased the
                cost of producing and transporting solar photovoltaics modules,
                wind turbines and biofuels worldwide, adding uncertainty to a
                development trajectory that is already far below Goal 7
                ambitions. Achieving energy and climate goals will require
                continued policy support and a massive mobilization of public
                and private capital for clean and renewable energy, especially
                in developing countries.
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-4 mt-5 p-3 bg-white">
        <h1 className="text-4xl text-aerogenBlue text-center">
          Our vision and values
        </h1>
        <h1 className="text-xl mt-3 text-aerogenGreen text-center">
          Creating a world that runs entirely on green energy
        </h1>
        <p className="text-black text-center mt-3">
          We believe that it is time to take real action to create a world that
          runs on green energy. Renewable energy holds the key to a cleaner
          future, and the planet needs to act now to reduce the effects of
          climate change.
        </p>
        <div className="row padding">
          <div className="col-lg-6">
            <img src={vision} alt="" className="h-fit w-fit" />
          </div>
          <div className="col-lg-6">
            <h1 className="text-lg text-aerogenBlue mt-5">
              Keeping the global temperature down
            </h1>
            <p className="text-black text-lg">
              Since the Paris agreement took effect in 2016, the international
              community has begun a common path to address climate change,
              working towards keeping the global temperature rise below 1.5°C.
            </p>
            <h1 className="text-lg text-aerogenBlue">
              From fossil fuels to renewable energy
            </h1>
            <p className="text-black text-lg">
              Despite the positive progress being made, we believe that more can
              be done to reduce the effects of climate change. More than a third
              of global CO2 emissions come from power production. One third.
              This huge number is in part what made us completely rethink our
              business strategy a decade ago, causing us to initiate our
              transformation from a fossil fuels-driven energy company to a
              renewable energy company.
            </p>
          </div>
        </div>
        <div className="bg-white mt-5 p-3">
          <div className="row padding">
            <div className="col-lg-6">
              <h1 className="text-3xl text-aerogenBlue mt-5">
                On the path to net zero,
                <br />
                we’re just getting started
              </h1>
              <p className="text-black text-lg mt-3">
                Renewable energy is powering green economic growth, creating
                energy security, and having a positive impact on communities and
                businesses.
              </p>
              <h1 className="text-xl mt-5 text-aerogenBlue">
                Corporate Responsibility
              </h1>
              <p className="text-black text-lg mt-3">
                We operate a sustainable business that respects communities,
                environment, and our employees
              </p>
            </div>
            <div className="col-lg-6">
              <div className="flex justify-center items-center p-3">
                <img
                  src={windTurbines}
                  alt="turbines"
                  className="h-fit w-fit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
