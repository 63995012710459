import React, { useState, useEffect } from 'react';
import arrowTop from '../Assets/Icons/up-arrow.svg';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      className={`fixed bottom-8 right-8 bg-aerogenYellow text-lg text-black flex space-x-2 p-2 rounded-3xl ${
        isVisible ? 'opacity-100' : 'opacity-0'
      } transition-opacity duration-300`}
      onClick={scrollToTop}
    >
      <img src={arrowTop} alt="back" className="h-fit w-fit" />
      Back to top
    </button>
  );
};

export default BackToTop;
