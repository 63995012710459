import React from 'react';
import facebook from '../Assets/Icons/facebook.svg';
import twitter from '../Assets/Icons/twitter.svg';
import linkedin from '../Assets/Icons/linkedin.svg';
const Footer = () => {
  return (
    <div className="bg-black p-5">
      <div className="row padding">
        <div className="col-lg-3">
          <div className="mt-2">
            <h1 className="text-xl text-white">Location</h1>
            <p className="text-lg text-white mt-3">Nairobi, Kenya</p>
            <p className="text-lg text-white mt-3">Thika Road, street 104</p>
          </div>
          <div className="mt-5">
            <p className="text-lg text-aerogenYellow mt-4">
              <span className="text-white">&copy;</span>
              2023 Aerogen. All Rights Reserved
            </p>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="mt-2">
            <h1 className="text-xl text-white">Contact Us</h1>
            <p className="text-lg text-white mt-3">+254-706-569-258</p>
            <p className="text-lg text-white mt-3">@aerogen.go.ke</p>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="mt-2">
            <h1 className="text-xl text-white mt-3">Socials</h1>
            <div className="flex space-x-1 mt-3">
              <img src={twitter} alt="twitter" className="h-fit w-fit" />
              <p className="text-lg text-white">@Aerogen</p>
            </div>
            <div className="flex space-x-1 mt-2">
              <img src={facebook} alt="twitter" className="h-fit w-fit" />
              <p className="text-lg text-white ">@Aerogen</p>
            </div>
            <div className="flex space-x-1 mt-2">
              <img src={linkedin} alt="twitter" className="h-fit w-fit" />
              <p className="text-lg text-white">@Aerogen</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
