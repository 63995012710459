import React from 'react';
import solar from '../Assets/Icons/Solar-panel-1200x750.png';
import tesla from '../Assets/Icons/tesla-roadster.png';
import Footer from '../Components/Footer';
import Navigation from '../Components/Navigation';
const Gallery = () => {
  return (
    <>
      <Navigation isScrolled={true} />
      <div className="mt-38 mx-30 mb-4 bg-white">
        <h1 className=" text-aerogenBlue text-5xl  font-semibold">Gallery</h1>
        <p className="mt-3 text-aerogenBlue text-lg">See Our Activities</p>
        <div className="row padding">
          <div className="col-lg-6">
            <img src={tesla} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={solar} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={solar} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={tesla} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={tesla} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={solar} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={solar} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={tesla} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={tesla} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={solar} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={solar} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={tesla} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={tesla} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={solar} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={solar} alt="gallery" className="h-full-w-full mt-3" />
          </div>
          <div className="col-lg-6">
            <img src={tesla} alt="gallery" className="h-full-w-full mt-3" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
