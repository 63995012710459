import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Blog from './Pages/Blog';
import Gallery from './Pages/Gallery';
import ContactUs from './Pages/ContactUs';
import 'bootstrap/dist/css/bootstrap.min.css';
import BackToTop from './Components/BackToTop';
import Services from './Pages/Services';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <BackToTop />
    </>
  );
};

export default App;
