import React from 'react';
import Logo from '../Assets/Icons/Aerogen-06 1.png';
import { NavLink } from 'react-router-dom';
const Navigation = ({ isScrolled }) => {
  return (
    <nav
      className={`${
        isScrolled
          ? 'bg-white border-b-2 border-aerogenGrey'
          : 'bg-transparent'
      } p-0 fixed top-0 left-0 w-full z-20`}
    >
      <div className="max-w-screen-xl flex flex-wrap items-center space-x-52 mx-auto p-2">
        <a href="https://flowbite.com/" className="flex items-center">
          <img src={Logo} alt="logo" className="h-fit w-fit rounded-lg" />
        </a>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-black rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="font-medium flex flex-col list-none p-4 md:p-0  md:flex-row md:space-x-8 md:mt-0 md:border-0  ">
            <li>
              <NavLink
                className={({ isActive }) =>
                  isScrolled
                    ? isActive
                      ? 'text-aerogenBlue text-lg  no-underline border-b-2 p-2 border-aerogenBlue '
                      : 'text-aerogenGrey text-lg  no-underline p-2'
                    : isActive
                    ? 'text-white text-lg  no-underline border-b-2 p-2 border-white '
                    : 'text-white text-lg  no-underline p-2'
                }
                to="/"
                exact
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isScrolled
                    ? isActive
                      ? 'text-aerogenBlue text-lg  no-underline border-b-2 p-2 border-aerogenBlue '
                      : 'text-aerogenGrey text-lg  no-underline p-2'
                    : isActive
                    ? 'text-white text-lg  no-underline border-b-2 p-2 border-white '
                    : 'text-white text-lg  no-underline p-2'
                }
                to="/about-us"
              >
                About-us
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isScrolled
                    ? isActive
                      ? 'text-aerogenBlue text-lg  no-underline border-b-2 p-2 border-aerogenBlue '
                      : 'text-aerogenGrey text-lg  no-underline p-2'
                    : isActive
                    ? 'text-white text-lg  no-underline border-b-2 p-2 border-white '
                    : 'text-white text-lg  no-underline p-2'
                }
                to="/blog"
              >
                Blog
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isScrolled
                    ? isActive
                      ? 'text-aerogenBlue text-lg  no-underline border-b-2 p-2 border-aerogenBlue '
                      : 'text-aerogenGrey text-lg  no-underline p-2'
                    : isActive
                    ? 'text-white text-lg  no-underline border-b-2 p-2 border-white '
                    : 'text-white text-lg  no-underline p-2'
                }
                to="/gallery"
              >
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isScrolled
                    ? isActive
                      ? 'text-aerogenBlue text-lg  no-underline border-b-2 p-2 border-aerogenBlue '
                      : 'text-aerogenGrey text-lg  no-underline p-2'
                    : isActive
                    ? 'text-white text-lg  no-underline border-b-2 p-2 border-white '
                    : 'text-white text-lg  no-underline p-2'
                }
                to="/services"
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isScrolled
                    ? isActive
                      ? 'text-aerogenBlue text-lg  no-underline border-b-2 p-2 border-aerogenBlue '
                      : 'text-aerogenGrey text-lg  no-underline p-2'
                    : isActive
                    ? 'text-white text-lg  no-underline border-b-2 p-2 border-white '
                    : 'text-white text-lg  no-underline p-2'
                }
                to="/contact-us"
              >
                Contact-us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
