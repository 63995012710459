import React from 'react';
import Navigation from '../Components/Navigation';
import { BLOGS } from '../constants/Constants';
import BlogCard from '../Components/BlogCard';
import Footer from '../Components/Footer';

const Blog = () => {
  return (
    <>
      <Navigation isScrolled={true} />
      <div className="mt-38 mx-30 bg-white">
        <h1 className=" text-aerogenBlue text-5xl  font-semibold">Blogs</h1>
        <p className="mt-3 text-aerogenBlue text-lg">
          Get the latest updates on new energy advancement
        </p>
        <div className="row padding">
          {BLOGS.map((blog) => (
            <div key={blog.author} className="col-lg-3">
              <BlogCard
                image={blog.image}
                title={blog.title}
                author={blog.author}
                date={blog.date}
                link={blog.link}
              />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
