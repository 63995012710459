import React, { useEffect, useState } from 'react'
import Navigation from '../Components/Navigation'
import ServiceOne from '../Assets/Icons/serviceOne.png'
import ServiceTwo from '../Assets/Icons/serviceTwo.png'
import ServiceThree from '../Assets/Icons/serviceThree.png'

const Services = () => {
    const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
    <Navigation isScrolled={true} />
    <div className="mt-36 p-4">
        <h1 className='text-5xl text-aerogenGreen font-semibold  text-center'>Our Services</h1>
        <p className="mt-3 text-center text-aerogenBlue font-normal text-xl">
            Unleash the Power of the Wind: Aerogen turbines capture the kinetic energy from wind and convert it into electricity, allowing you to tap<br/>
 into an abundant and renewable energy source.
        </p>
        <div className="mt-7">
            <h1 className="text-3xl font-medium text-aerogenGreen">Why Choose Us</h1>
        </div>
        <div className="row padding">
            <div className="col-lg-6">
                <div className="mt-4 h-40">
<h1 className="text-xl text-aerogenBlue">Compact and Efficient</h1>
<p className="mt-0">
    Our small wind turbines are designed to maximize energy <br/>
production even in low-wind conditions. With advanced aerodynamics<br/>
and state-of-the-art materials, our turbines are built to perform<br/>
optimally in various environments. Compact and Efficient
</p>
                </div>
                <div className="mt-4 h-40">
<h1 className="text-xl text-aerogenBlue">Eco-Friendly</h1>
<p className="mt-0">
    Our small wind turbines are designed to maximize energy production<br/>
even in low-wind conditions. With advanced aerodynamics and<br/>
state-of-the-art materials, our turbines are built to perform optimally<br/>
in various environments. Compact and Efficient
</p>
                </div>
            </div>
             <div className="col-lg-6">
                <div className="mt-4 h-40">
<h1 className="text-xl text-aerogenBlue">Reliable and Long-lasting</h1>
<p className="mt-0">
    Our small wind turbines are designed to maximize energy <br/>
production even in low-wind conditions. With advanced <br/>
aerodynamics and state-of-the-art materials, our turbines <br/>
are built to perform optimally in various environments.
Compact and Efficient
</p>
                </div>
                <div className="mt-4 h-40">
<h1 className="text-xl text-aerogenBlue">Versatile Applications</h1>
<p className="mt-0">
    Our small wind turbines are designed to maximize energy <br/>
production even in low-wind conditions. With advanced <br/>
aerodynamics and state-of-the-art materials, our turbines <br/>
are built to perform optimally in various environments. <br/>
Compact and Efficient
</p>
                </div>
                
            </div>
        </div>
        <div className="mt-16">
                    <div className="text-4xl font-semibold text-aerogenGreen">Services</div>
                </div>
                <div className="row padding">
                    <div className="col-lg-6">
                        <h1 className="text-2xl mt-2 text-aerogenBlue font-semibold">
                            HIGHWAY WINDFARM
                        </h1>
                        <img src={ServiceOne} alt="service-one" className="h-fit mt-1.5 w-fit" />
                        <p className="mt-3 5">
                            Electric Vehicles will achieve their environmental benefit only<br/>
when charged by renewable energy. Charging EV through fossil<br/>
powered Grid power only shifts the point of pollution to EV systems.<br/>
Electric Vehicles continue to require EV charging post sunset also<br/>
when solar power ceases to exist. <span> <a href="#">Read more</a> </span>
                        </p>
                         <h1 className="text-2xl mt-2 text-aerogenBlue font-semibold">
                            CONNECTED WIND TURBINES
                        </h1>
                        <img src={ServiceTwo} alt="service-one" className="h-fit mt-1.5 w-fit" />
                        <p className="mt-3 5">
                            Electric Vehicles will achieve their environmental benefit only<br/>
when charged by renewable energy. Charging EV through fossil<br/>
powered Grid power only shifts the point of pollution to EV systems.<br/>
Electric Vehicles continue to require EV charging post sunset also<br/>
when solar power ceases to exist. <span> <a href="#">Read more</a> </span>
                        </p>
                    </div>
                     <div className="col-lg-6">
                        <h1 className="text-2xl mt-2 text-aerogenBlue font-semibold">
                            WATER PUMPING SOLUTION
                        </h1>
                        <img src={ServiceThree} alt="service-one" className="h-fit mt-1.5 w-fit" />
                        <p className="mt-3 5">
                            Wind solar Hybrid Power Solutions For Pumpsets has been developed to <br/>
                             generate power from wind & sunlight natural resources a Renewable <br/>
                              Energy system SWT wind solar hybrid is a special design for the bore well <br/>
                               pump sets A Proven design ideally suited for running bore well pump <br/>
                                sets throughout the year.  <span> <a href="#">Read more</a> </span>
                        </p>
                        
                    </div>
                </div>
    </div>
    </>
  )
}

export default Services