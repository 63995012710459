import React from 'react';
import Navigation from '../Components/Navigation';
import planet from '../Assets/Icons/planet.png';
import forwardButton from '../Assets/Icons/forward-button.svg';
import Footer from '../Components/Footer';

const AboutUs = () => {
  return (
    <div>
      <Navigation isScrolled={true} />
      <div className="mt-38 mx-30 bg-white">
        <h1 className="text-center text-aerogenBlue text-5xl  font-semibold">
          Get to know us
        </h1>
        <p className="mt-3 text-lg text-black text-center">
          The Aerogen group is today a global energy leader. We have brought the
          energy transition forward two decades to combat climate change and
          offer a sustainable and competitive business model that creates value
          for society.
        </p>
        <div className="flex justify-center items-center">
          <h1 className="text-4xl text-aerogenGreen font-semibold">For you.</h1>
          <img src={planet} alt="planet" className="h-fit w-fit" />
          <h1 className="text-4xl text-aerogenGreen font-semibold">
            For the planet.
          </h1>
        </div>
        <h1 className="font-bold text-lg text-aerogenBlue underline">
          Our Activity
        </h1>
        <p className="mt-3 text-lg text-black">
          We are committed to the energy transition with a sustainable business
          model based on renewable energy, smart grids, large-scale energy
          storage and digital transformation to offer the most advanced products
          and services to our customers.
        </p>
        <div className="mx-36 my-6">
          <div className="row padding">
            <div className="col-lg-6">
              <div className="border-2 p-3 flex justify-between items-center border-aerogenGrey">
                <h1 className="text-lg text-aerogenBlue">
                  Management Team: maximise the operation efficiency
                </h1>
                <img src={forwardButton} alt="button" className="h-fit w-fit" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="border-2 p-3 flex justify-between items-center border-aerogenGrey">
                <h1 className="text-lg text-aerogenBlue">
                  Our brand: committed to sustainability and innovation
                </h1>
                <img src={forwardButton} alt="button" className="h-fit w-fit" />
              </div>
            </div>
          </div>
        </div>
        <h1 className="font-bold text-black text-4xl mt-14 ">
          Ways which wind turbines can have a <span className='text-aerogenGreen'>positive impact</span> 
        </h1>
        <h1 className="font-medium text-aerogenGreen text-2xl mt-6 ">
          Renewable Energy Generation
        </h1>
        <p className="text-black mt-5 text-xl font-medium">
          Small wind turbines harness the power of wind to generate electricity. By utilizing a clean and renewable energy source, they<br/>
contribute to reducing reliance on fossil fuels and the associated carbon emissions. This helps combat climate change and<br/>
promotes a more sustainable energy future
        </p>
        <h1 className="font-medium text-aerogenGreen text-2xl mt-6 ">
          Energy Independence
        </h1>
        <p className="text-black mt-5 text-xl font-medium">
          Installing a small wind turbine allows individuals or communities to generate their own electricity on-site. This can provide <br/>
energy independence, especially in remote or off-grid locations. It reduces dependence on centralized power grids and the <br/>
associated transmission losses, enhancing energy resilience.
        </p>
        <h1 className="font-medium text-aerogenGreen text-2xl mt-6 ">
          Cost Savings
        </h1>
        <p className="text-black mt-5 text-xl font-medium">
          Small wind turbines can help reduce electricity bills. When properly sized and installed, they can generate a significant portion <br/>
of a household's or a small business's electricity needs. This offsets the need to purchase power from utility providers, resulting <br/>
in long-term cost savings.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
