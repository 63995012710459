import React from 'react';

const BlogCard = ({ image, title, author, date, link }) => {
  return (
    <div className="card bg-white rounded-2xl border-2 border-aerogenGrey mb-3">
      <img src={image} alt="blog" className="h-full w-full" />
      <hr className="mb-2 border-aerogenGrey" />
      <div className="p-3">
        <h1 className="text-black text-lg">{title}</h1>
        <div className="flex justify-between items-center mt-3">
          <div className="p-2">
            <p className="text-sm text-black">{author}</p>
            <p className="text-sm text-black">{date}</p>
          </div>
          <a href={link} className="p-2 text-aerogenDarkGrey text-sm underline">
            Read more
          </a>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
