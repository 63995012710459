import blog from '../Assets/Icons/blog.png';
const BLOGS = [
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
  {
    image: blog,
    title: 'New York Climate Week',
    author: 'John Doe',
    date: '15-12-2023',
    link: '#',
  },
];

export { BLOGS };
